import axios from 'axios';
import client from './Data';

const SetStat = (code_event=null, id_anncs=null) => {
   
    // const res = axios.get('https://ipapi.co/json')
    // console.log(res.data);

    const IP = Math.floor(Math.random() * 100000);
    client
    .post("/visite", {
      code_event : code_event,
      id_anncs : id_anncs,
      url : window.location.pathname,
      ip : IP
    })
    .then((res) => {
      return res.data.messageJson
    })
    .catch((err) => {
      return "Erreur de l'enregistrement de la visite"
    } )
  }

export default SetStat;