import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Register from '../../utils/functions/auth/Register'
import { AuthContext } from "../../context/AuthContext";
import { Checkbox, TextField, FormControlLabel, Button } from "@mui/material";
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
// import { ReactComponent as PersonalInfo } from "../../assets/img/personalInfo.svg";
import LoginP from '../../assets/img/Login.png';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import '../../styles/main/login.css';


const ProfileSetup = () => {
  const { currentStep, setStep, user, setUser, SubmitData, setIsLogin, setIsAnonyme } = useContext(AuthContext);
  const [phoneError, setPhoneError] = useState('');
  const [formErrors, setFormErrors] = useState({
    fullname: '',
    email: '',
    tel: ''
  });
  const [passwordError, setPasswordError] = useState("");
  const navigate = useNavigate();

  // Validation du numéro de téléphone
  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
      setPhoneError('Le numéro de téléphone est requis');
      return false;
    }

    // Vérifie si le numéro est valide selon MuiTelInput
    const isValid = matchIsValidTel(phoneNumber);
    if (!isValid) {
      setPhoneError('Numéro de téléphone invalide');
      return false;
    }

    // Retire tous les caractères non numériques
    const numericOnly = phoneNumber.replace(/\D/g, '');

    // Vérifie si le numéro commence par l'indicatif du Bénin (229)
    if (!numericOnly.startsWith('229')) {
      setPhoneError('Le numéro doit commencer par +229');
      return false;
    }

    // Vérifie la longueur pour le Bénin
    if (numericOnly.length !== 11) { // 229 + 8 chiffres
      setPhoneError('Le numéro doit contenir 8 chiffres');
      return false;
    }

    // Vérifie les préfixes valides pour le Bénin
    const validPrefixes = ['9', '6', '5', '4', '2'];
    const prefix = numericOnly.substring(3, 4);
    if (!validPrefixes.includes(prefix)) {
      setPhoneError('Préfixe de numéro invalide pour le Bénin');
      return false;
    }

    setPhoneError('');
    return true;
  };

  // Validation de l'email
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setFormErrors(prev => ({ ...prev, email: "L'email est requis" }));
      return false;
    }
    if (!emailRegex.test(email)) {
      setFormErrors(prev => ({ ...prev, email: "Format d'email invalide" }));
      return false;
    }
    setFormErrors(prev => ({ ...prev, email: '' }));
    return true;
  };

  // Validation du nom complet
  const validateFullname = (fullname) => {
    if (!fullname || fullname.trim().length < 2) {
      setFormErrors(prev => ({ ...prev, fullname: 'Le nom complet est requis (minimum 2 caractères)' }));
      return false;
    }
    setFormErrors(prev => ({ ...prev, fullname: '' }));
    return true;
  };

  const handleChange = (newValue) => {
    setUser({ ...user, 'tel': newValue });
    validatePhoneNumber(newValue);
  };

  //mot de passe validation

  const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    
    const errors = [];
    
    if (password.length < minLength) {
        errors.push(`Il manque ${minLength - password.length} caractère(s)`);
    }
    if (!hasNumber) {
        errors.push("un chiffre");
    }
    if (!hasUpperCase) {
        errors.push("une majuscule");
    }

    if (errors.length > 0) {
        return `Il manque : ${errors.join(", ")}`;
    }
    return "";
};

const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setUser({...user, 'mdp': newPassword});
    setPasswordError(validatePassword(newPassword));
};

  /* const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation de tous les champs
    const isPhoneValid = validatePhoneNumber(user.tel);
    const isEmailValid = validateEmail(user.email);
    const isFullnameValid = validateFullname(user.fullname);

    if (!isPhoneValid || !isEmailValid || !isFullnameValid) {
      return;
    }

    await SubmitData(user);
    
    if (localStorage.getItem('username') !== null) {
      setIsLogin(true);
      setIsAnonyme(false);
      navigate('/mon-compte');
    }
  }; */

  const gotoLoginPage = () => navigate("/signin");

  /* const goNext = () => {
    setStep(2);
    navigate('/preference');
  }; */

  const goNext = () => {
    // Validation des champs
    const isPhoneValid = validatePhoneNumber(user.tel);
    const isEmailValid = validateEmail(user.email);
    const isFullnameValid = validateFullname(user.fullname);
    const isPasswordValid = !passwordError; // Le mot de passe est valide si aucun message d'erreur n'est présent
  
    // Si l'un des champs n'est pas valide, on arrête la navigation
    if (!isPhoneValid || !isEmailValid || !isFullnameValid || !isPasswordValid) {
      return;
    }
  
    // Si tout est valide, on passe à l'étape suivante
    setStep(2);
    navigate('/preference');
  };

  return (
    <div className='signup__container'>
      {/* <h2>INSCRIT TOI</h2>
      <div className="mb-3 flex justify-center">
        <PersonalInfo />
      </div> */}
       <h2 style={{textAlign:'left'}}>CREE TON COMPTE</h2>
            <h3 className="h3">C'est facile !</h3>
            <div>
                <img src={LoginP} alt="Login" className="login-image" />
            </div>
      <form className='signup__form' /* onSubmit={handleSubmit} */>
        <TextField 
          label="Prénom" 
          value={user.fullname} 
          onChange={(e) => {
            setUser({ ...user, 'fullname': e.target.value });
            validateFullname(e.target.value);
          }}
          margin="normal" 
          variant="outlined" 
          className="input-field"
          color="primary" 
          type="text" 
          id="fullname" 
          name="fullname"
          error={!!formErrors.fullname}
          helperText={formErrors.fullname}
          required
        />

        <TextField 
          label="Email" 
          value={user.email} 
          onChange={(e) => {
            setUser({ ...user, 'email': e.target.value });
            validateEmail(e.target.value);
          }}
          margin="normal" 
          variant="outlined" 
          className="input-field"
          color="primary" 
          type="email" 
          id="email" 
          name="email"
          error={!!formErrors.email}
          helperText={formErrors.email}
          required 
        />

        <MuiTelInput 
          label="Numéro WhatsApp" 
          defaultCountry="BJ" 
          value={user.tel} 
          onChange={handleChange}
          className="input-field"
          margin="normal" 
          name="tel" 
          id="tel"
          error={!!phoneError}
          helperText={phoneError}
          required
          forceCallingCode
        />

        <TextField 
                    label="Mot de passe" 
                    value={user.mdp} 
                    onChange={handlePasswordChange}
                    margin="normal" 
                    variant="outlined" 
                    className="input-field"
                    color="primary" 
                    type="password" 
                    id="password" 
                    name="password" 
                    required 
                    error={!!passwordError}
                    helperText={passwordError}
                    sx={{ borderColor: '#000000' }} 
        />
                
        {/* <p style={{fontSize:'0.8rem'}}>Le mot de passe doit respecter les critères suivant : au moins 8 caractères, 1 chiffre, 1 lettre majuscule</p> */}

       <div className="flex justify-between items-center mt-2" style={{justifyContent:'center'}}>
          {/* <button 
            type="submit" 
            className='signupBtn w-75'
            disabled={!!passwordError || !!phoneError || !!formErrors.email || !!formErrors.fullname}
          >
            SUIVANT
          </button> */}

        <button 
        type="button" 
        className='loginBtn' 
        variant="contained" 
        onClick={goNext}
        >
          SUIVANT
        </button>
        </div> 
      </form>
      <p>
        Vous avez déjà un compte ?{" "}
        <span className='link' onClick={gotoLoginPage}>
          Se connecter
        </span>
      </p>
    </div>
  );
};

export default ProfileSetup;