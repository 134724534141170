import React from 'react';
import { Outlet, useLocation } from 'react-router';
import Footer from '../components/Footer';
import OffCanvasMenu from '../components/offcanvasmenu/OffCanvasMenu';
import SidebarMenu from '../components/SidebarMenu';

const Root = () => {
    const location = useLocation();
    
    // Les chemins où le footer ne doit pas être affiché
    const hiddenFooterPaths = ["/redirect/:idevent", "/mon-compte", "/signup", "/signin", "/preference", "/setup", "/setup-profil"];

    const isRedirectPath = location.pathname.startsWith("/redirect/");
    const shouldHideFooter = hiddenFooterPaths.includes(location.pathname) || isRedirectPath;
    
    return (
        <div id="outer-container">
            <SidebarMenu />
            <div id="page-wrap">
                <OffCanvasMenu />
                <Outlet />
                {/* {!shouldHideFooter && <Footer />} */}
            </div>
        </div>
    );
};

export default Root;