import React from "react";
import { useTimer } from "react-timer-hook";

const TimerPage = ({ expiryTimestamp, postExpire }) => {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => postExpire() });

  return (
        <div style={{ textAlign: "center" }}>
          {/* <p>patientez encore... </p> */}

          <div style={{ fontSize: "50px" }}>
          <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
          </div>
          {/* <p>{isRunning ? 'Running' : 'Not running'}</p> 
          <button onClick={start}>Start</button>
          <button onClick={pause}>Pause</button>
          <button onClick={resume}>Resume</button>
          <button onClick={() => {
            // Restarts to 5 minutes timer
            const time = new Date();
            time.setSeconds(time.getSeconds() + 1800);
            restart(time)
          }}>Restart</button> */}
       </div>
   
  );
};

export default TimerPage;
