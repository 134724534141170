import React from 'react';
import styles from './startBtn.module.css'
import { NavLink } from 'react-router-dom';

const BtnStart = ({text, toUrl}) => {

    const navStyle = {
        display : 'contents'
    };

    return (
          
            <div className={styles.wrapper}>
                <NavLink to={toUrl} style={navStyle} >
                    <span className={styles.mySuperCoolBtn}>
                        <div className={styles.dotsContainer}>
                            <div className={styles.dot}></div>
                            <div className={styles.dot}></div>
                            <div className={styles.dot}></div>
                            <div className={styles.dot}></div>
                        </div>
                        <span>{text}</span>
                    </span>
                </NavLink>
            </div>
                
     
        
    );
};

export default BtnStart;