import React, { useContext } from 'react';
import { slide as Menu } from 'react-burger-menu';
import "../styles/components/sidebarMenu.css";
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { MainFlowContext } from '../context/FlowContext';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { MdEmail, MdLanguage } from 'react-icons/md';
import { IoMdHeart } from "react-icons/io";
/* import { RiWhatsappFill } from "react-icons/ri"; */

const SidebarMenu = () => {
    const { user, logout, isLogin, isAnonyme } = useContext(AuthContext);
    const { isMenuOpen, stateChangeHandler, closeMenu } = useContext(MainFlowContext);

    return (
        <Menu
            right
            pageWrapId={"page-wrap"}
            outerContainerId={"outer-container"}
            customBurgerIcon={false}
            isOpen={isMenuOpen}
            onStateChange={(state) => stateChangeHandler(state)}
        >
            <div className="sidebar-header">
                {/* <p className="subtitle text-light">Salut @{user?.username || "anonyme"}</p> */}
            </div>
                <button className="retry-btn"><NavLink to="/scanpage" onClick={closeMenu} className="text text-light " style={{textDecoration : "none"}} >Essaie à nouveau</NavLink></button>
            
            <div className="menu-items">
                {/* <NavLink to="/" onClick={closeMenu} className="nav-link">
                    <i className="fa fa-home icon"></i> <span className="text-background">Accueil</span>
                </NavLink> */}
                <a
                    href="https://wa.me/+22940538164"
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="nav-link" 
                    onClick={closeMenu}
                >
                  <i className="fa fa-whatsapp icon"></i> <span className="text-background">WhatsApp</span>
                </a>

                {
                    isLogin && <NavLink to="/mon-compte" onClick={closeMenu} className="nav-link">
                    <i className="far fa-user icon"></i> <span className="text-background">Mon compte</span>
                </NavLink>
                }
                
                
                {/* <NavLink to="/scanpage" onClick={closeMenu} className="nav-link">
                    <i className="fa fa-qrcode icon"></i> <span className="text-background">Scanner</span>
                </NavLink>
                 */}

                 {
                    isLogin &&  <NavLink to="/annonces-gardees" onClick={closeMenu} className="nav-link">
                    <i className="far fa-bookmark icon"></i> <span className="text-background">Les enregistrées</span>
                </NavLink>
                 }
               
                
                {/* <a href="https://app.wescan.gift/aide" onClick={closeMenu} target='_blank' rel='noreferrer' className="nav-link">
                    <i className="far fa-comment-dots icon"></i> Aide
                </a>
                
                <a href="https://app.wescan.gift/services" onClick={closeMenu} className="nav-link">
                    <i className="fas fa-file-alt icon"></i> Services
                </a>
                
                <a href="https://dashboard.wescan.gift" onClick={closeMenu} target='_black' className="nav-link">
                    <i className="fas fa-bolt icon"></i> Tableau de bord
                </a> */}
                
                {isLogin && !isAnonyme ? (
                    <p onClick={logout} className="nav-link logout">
                        <i className="fas fa-sign-out-alt icon"></i> Se déconnecter
                    </p>
                ) : (
                    <NavLink to="/mon-compte" onClick={closeMenu} className="nav-link">
                        <i className="fas fa-user-check icon"></i> Se connecter
                    </NavLink>
                )}
            </div>

            <div className="mt-auto sidebar-footer">
                <a href="mailto:hello@wescan.gift" target="_blank" rel="noopener noreferrer" className="footer-link">
                    <MdEmail className="icon" /> <span className="text-background">Envoyer un e-mail</span>
                </a>
                <button className="footer-link d-flex align-items-center" onClick={() => navigator.share({title: 'WeScanGift',text: 'Découvrez cette application incroyable !',url: window.location.href,})} style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}>
                    <IoMdHeart className="heart" /> <span className="text-background">Partage à un ami</span>
                </button>
                <a href="https://app.wescan.gift/" target="_blank" rel="noopener noreferrer" className="footer-link">
                    <MdLanguage className="icon" /> <span className="text-background">Site internet</span>
                </a>
                <a href="https://docs.google.com/document/d/1bBKd7ok3_z64dqPgvEf_rTBo87DGZLMS/edit?usp=sharing&ouid=102237904479276098808&rtpof=true&sd=true" className="footer-link">
                    <IoDocumentTextOutline className="icon" /> <span className="text-background">CGU & Politiques</span>
                </a>
            </div>
        </Menu>
    );
};

export default SidebarMenu;
