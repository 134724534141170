import React, { useState, useContext,useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Register from '../../utils/functions/auth/Register'
import PrefButton from "../../components/PrefButton";
import {AuthContext} from "../../context/AuthContext";
import { Button, Checkbox, TextField } from "@mui/material";
import BtnPrimary from "../../components/btnPrimary/BtnPrimary";
import ButtonPrimary from "../../components/ButtonPrimary";
import '../../styles/main/login.css';
/* import { ReactComponent as SelectPref } from "../../assets/img/selectPref.svg"; */

const PreferenceSetup = () => {

  const navigate = useNavigate()

  const { currentStep, setStep, user, setUser, selectedPreferences, setSelectedPreferences, SubmitData, prefList, isLogin, setIsLogin, isAnonyme, setIsAnonyme } = useContext(AuthContext);

    const handlePreferenceClick = (preference) => { 
       
      // Vérifie si la préférence est déjà sélectionnée
      const isAlreadySelected = selectedPreferences.includes(preference);
  
      if (isAlreadySelected) {
        // Si déjà sélectionnée, la retire du tableau des préférences
        setSelectedPreferences(selectedPreferences.filter((item) => item !== preference));
      } else {
        // Si non sélectionnée, l'ajoute au tableau des préférences
        setSelectedPreferences([...selectedPreferences, preference]);
      }
    };
  
    const isPreferenceSelected = (preference) => {
      return selectedPreferences.includes(preference);    
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      const dateNaissanceTimestamp = new Date(user.date_naissance).getTime();
  
      // Validation de tous les champs
      /* const isDateNaissanceValid = validateDateNaissance(user.date_naissance);
      const isProfessionValid = validateProfession(user.profession_id, user.autre_profession);
      const isAdresseValid = validateAdresse(user.adresse);
  
      if ( !isDateNaissanceValid || !isProfessionValid || !isAdresseValid || !!passwordError) {
        return;
      } */
  
      await SubmitData({ ...user, date_naissance: dateNaissanceTimestamp, preferences: selectedPreferences });
  
      if (localStorage.getItem('userId') !== null) {
        setIsLogin(true);
        setIsAnonyme(false);
        navigate('/mon-compte');
      }
    };

    /* const goNext = ()=> {
      setStep(2)
      navigate('/signup')
    } */

    const goBack = () => {
      setStep(1);
      navigate('/setup-profil');
  };


    return (
    <div className="signup__container" style={{ padding: '10px', maxWidth: '400px', margin: '0 auto', textAlign: 'left' }}>
      <h2 style={{textAlign:'left', fontSize:'24px'}}>Tu es au bon endroit</h2>
      <h3 className="h3">Salut {user.fullname ? user.fullname.split(" ")[0] : ""}, quelles catégories de produits t'intéressent ?</h3>

      <div className="my-1 grid grid-cols-2 gap-2" style={{ gridTemplateColumns: 'repeat(3, 1fr)', gap: '8px', marginTop: '10px' }}>
        {prefList.map((pref) => (
          <PrefButton key={pref} condition={isPreferenceSelected(pref)} onClickFunction={() => handlePreferenceClick(pref)}>
            {pref}
          </PrefButton>
        ))}
      </div>

      <div className="login__container">
        <div className="login__form">
        <TextField
            label="Date de naissance"
            variant="outlined"
            margin="normal"
            fullWidth
            className="input-field"
            value={user.date_naissance}
            onChange={(e) => setUser({ ...user, 'date_naissance': e.target.value })}
            required
            type="date"
          />
          <TextField
            label="Tu fais quoi dans la vie ?"
            variant="outlined"
            margin="normal"
            fullWidth
            className="input-field"
            value={user.profession_id}
            onChange={(e) => setUser({ ...user, 'profession_id': e.target.value })}
            required
          />
           <TextField
            label="Tu habites quel zone ?"
            variant="outlined"
            margin="normal"
            fullWidth
            className="input-field"
            value={user.adresse}
            onChange={(e) => setUser({ ...user, 'adresse': e.target.value })}
            required
          />
          <div style={{ marginTop: '1px', textAlign: 'left' }}>
            <Checkbox color="primary" />
            <span>Je veux recevoir des alertes pour les nouvelles offres.</span>
          </div>
          <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              style={{
                marginTop: '1px',
                width: '100%',
                padding: '12px',
                fontSize: '16px',
                fontWeight: 'bold',
                backgroundColor: '#004AAD',
                color: '#fff',
                borderRadius: '8px',
              }}
            >
              Je m'inscris
            </Button>
        <Button 
                        onClick={goBack}  
                        variant="outlined" 
                        className="text-secondary text-danger bg-light h-15"
                    >
                        <i className="fas fa-arrow-left"></i>
                    </Button>
        </div>

      </div>

      
    </div>
  );
};

export default PreferenceSetup;