import axios from "axios";
import { getBaseUrl } from "./functions/function";

const client = axios.create({
        baseURL : getBaseUrl() + process.env.REACT_APP_API_VERSION2 ,
        headers : {
                "Content-Type" : "application/json"
        }
});

client.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token'); // récupere le token dans le localstorage
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default client;