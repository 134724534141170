import React, {useEffect, useState, useContext} from 'react';
import { useNavigate, useParams, Navigate, Outlet, Route, redirect, NavLink } from 'react-router-dom';
import TimerPage from '../views/main/TimerPage';
import useCookie from './functions/gesCookie';
import { AuthContext } from '../context/AuthContext';
import SignIn from '../views/landing/SignIn';

const OnlyLoggedUserRoute = () => {


const { isLogin, isAnonyme } = useContext(AuthContext);


    return (
      isLogin && !isAnonyme && localStorage.getItem('token') ? <Outlet /> : <SignIn/>
    );
};

export default OnlyLoggedUserRoute;