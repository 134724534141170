import {useState, createContext } from "react";
import userSetter from "../utils/userSetter";
import { queryStrings } from "../utils/queryString";
import client from "../utils/axiosClient";
import { Navigate, useNavigate } from "react-router";
import axios from "axios";
import Register from "../utils/functions/auth/Register";
import Login from "../utils/functions/auth/Login";



export const AuthContext = createContext()

export function AuthProvider({ children }) {

    const [currentStep, setStep] = useState(1);
    const [user, setUser] = useState( localStorage.getItem('userData') !== null ? JSON.parse(localStorage.getItem('userData')) : {});
    const [selectedPreferences, setSelectedPreferences] = useState([]);
    const [finalData, setFinalData] = useState( localStorage.getItem('userData') !== null ? JSON.parse(localStorage.getItem('userData')) : {} );
    //const [finalData, setFinalData] = useState({});

    const [isLogin, setIsLogin] = useState( localStorage.getItem('userId') ? true : false);
    const [isAnonyme, setIsAnonyme] = useState(localStorage.getItem('token') ? false : true);
    const [reqResult, setreqResult] = useState();

    const prefList = ['Chaussures', 'Nourriture', 'Cosmétique', 'Forfaits mobiles', 'Amuse bouche', 'Glaces','Jus de fruits', 'Boissons', 'Gâteaux','Ongles', 'Salon de coiffure', 'Soin de corps', 'Montres', 'Accessoires électoniques','Fast Food', 'Lait', 'Transport', 'Fourniture de maison', 'Promo']
   
    // Définissez des fonctions pour gérer l'authentification ici
    const SubmitData = async (user) => {
      const dataToSubmit = { ...user, preferences: Array.isArray(selectedPreferences) ? selectedPreferences : [] }; // Assurez-vous que c'est un tableau
      setFinalData(dataToSubmit);
  
      try {
          const registrationResponse = await Register(dataToSubmit);
          if (registrationResponse.success) {
              localStorage.setItem('userData', JSON.stringify(dataToSubmit));
          } else {
              console.error("Registration failed:", registrationResponse.message);
          }
      } catch (error) {
          console.error("An error occurred during registration:", error);
      }
  };

    const usernameAvailable = async () => {

    }

  /**
   * Fonction de connexion de l'utilisateur
   * @param {object} user 
   */
  const userLogin = async (user) => {
    const data = {
        /* username: user.username, */
        tel: user.tel,
        mdp: user.mdp,
    };

    try {
        const resp = await client.post('/annonceur/login', data, {
            headers: {
                'Content-Type': 'application/json'
            },
        });

        if (!resp.data.succes) {
            alert(resp.data.msg);
            return false;
        } else {
            setFinalData(resp.data.data.user);
            setUser(resp.data.data.user);
            setIsAnonyme(false);
            setIsLogin(true);
            localStorage.setItem('userId', resp.data.data.user.id);
            localStorage.setItem('token', resp.data.data.token);
            localStorage.setItem('userData', JSON.stringify(resp.data.data.user));
            return true;
        }
    } catch (error) {
        console.error(error);
        if (error.response && error.response.data.error) {
            alert(error.response.data.msg);
        }
        return false;
    }
};

   /**
    * Mise à jour de l'utilisateur
    * @param {} user 
    */
   const userUpdate = (user) => {
    /* console.log('update user : ' + user) */
   }
  

   //pour la connexion anonyme
   const anonymeLogin = () => {
    
    const userId = userSetter.setAnonymeUser()
    setIsLogin(true)
    setIsAnonyme(true)
    setUser({'userId' : userId})
    setFinalData(user)

   } 

  // Fonction de déconnexion de l'utilisateur
  const logout = () => {
    //Logique de déconnexion...
     localStorage.removeItem('userId')
    localStorage.removeItem('userData')
    localStorage.removeItem('token')
    localStorage.removeItem('tel')
    setUser();
    setSelectedPreferences([])
    setFinalData({})


    anonymeLogin()

    setIsLogin(false) 
    setIsAnonyme(false)
    console.log('Déconnecté')
  };

  const handlePreferenceClick = (preference) => { 
       
    // Vérifie si la préférence est déjà sélectionnée
    const isAlreadySelected = selectedPreferences.includes(preference);

    if (isAlreadySelected) {
      // Si déjà sélectionnée, la retire du tableau des préférences
      setSelectedPreferences(selectedPreferences.filter((item) => item !== preference));
    } else {
      // Si non sélectionnée, l'ajoute au tableau des préférences
      setSelectedPreferences([...selectedPreferences, preference]);
    }
    

  };

  // const isPreferenceSelected = (preference) => {
  //   return selectedPreferences.includes(preference);    
  // };
  const isPreferenceSelected = (preference) => {
    if (!selectedPreferences || selectedPreferences.length === 0) {
        return false;
    }
    return selectedPreferences.includes(preference);    
};
  
    return (
      <AuthContext.Provider value={{currentStep, setStep, user, setUser, finalData, selectedPreferences, setSelectedPreferences, setFinalData, SubmitData, reqResult, userLogin, anonymeLogin, isLogin, isAnonyme,setIsAnonyme, setIsLogin,logout, prefList, handlePreferenceClick,isPreferenceSelected, userUpdate  }}>
        {children}
      </AuthContext.Provider>
    );
  }
  