import {useState, createContext } from "react";


export const MainFlowContext = createContext({})

export function FlowProvider({ children }) {
    const [user, setUser] = useState(null);
    const [scanNumber, setScanNumber] = useState(0);

    const [isWinner, setIsWinner] = useState(false);

    const [menuOpenState, setMenuOpenState] = useState(false)
 
    const toggleMenu = () => {
      setMenuOpenState(!menuOpenState);
  };

  const stateChangeHandler = (newState) => {
      setMenuOpenState(newState.isOpen);
  };

  const closeMenu = () => {
    if (menuOpenState) {
        stateChangeHandler({ isOpen: false });
    }
};

  
    // Définissez des fonctions pour gérer l'authentification ici
  
    return (
      <MainFlowContext.Provider value={{ user, setUser, scanNumber, setScanNumber, isWinner, setIsWinner, stateChangeHandler, menuOpenState, toggleMenu,  isMenuOpen: menuOpenState, closeMenu}}>
        {children}
      </MainFlowContext.Provider>
    );
  }