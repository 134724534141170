import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { TextField } from "@mui/material";
import LoginP from '../../assets/img/Login.png';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';

const SignIn = () => {
    const { user, setUser, userLogin } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    // Fonction de validation du numéro de téléphone
    const validatePhoneNumber = (phoneNumber) => {
        // Vérifie si le numéro est valide selon MuiTelInput
        const isValid = matchIsValidTel(phoneNumber);
        
        // Vérifications supplémentaires pour le Bénin
        if (!isValid) {
            setPhoneError('Numéro de téléphone invalide');
            return false;
        }

        // Retire tous les caractères non numériques
        const numericOnly = phoneNumber.replace(/\D/g, '');
        
        // Vérifie la longueur pour le Bénin (8 chiffres + indicatif pays)
        if (numericOnly.length !== 11) { // 229 (indicatif) + 8 chiffres
            setPhoneError('Le numéro doit contenir 8 chiffres pour le Bénin');
            return false;
        }

        // Vérifie si le numéro commence par l'indicatif du Bénin (229)
        if (!numericOnly.startsWith('229')) {
            setPhoneError('Le numéro doit commencer par +229');
            return false;
        }

        // Vérifie les préfixes valides pour le Bénin
        const validPrefixes = ['9', '6', '5', '4', '2']; // Ajoutez d'autres préfixes valides si nécessaire
        const prefix = numericOnly.substring(3, 4);
        if (!validPrefixes.includes(prefix)) {
            setPhoneError('Préfixe de numéro invalide pour le Bénin');
            return false;
        }

        setPhoneError('');
        return true;
    };

    const handlePhoneChange = (value) => {
        setUser({ ...user, 'tel': value });
        validatePhoneNumber(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validation des champs
        if (/* !user.username || */ !user.tel || !user.mdp) {
            setErrorMessage('Veuillez remplir tous les champs.');
            return;
        }
    
        // Validation spécifique du numéro de téléphone
        if (!validatePhoneNumber(user.tel)) {
            return;
        }
    
        // Transformation du numéro de téléphone au format souhaité
        const formattedPhone = user.tel.replace(/\D/g, '');
        /* console.log("Numéro de téléphone formaté envoyé à l'API :", formattedPhone); */
    
        // Mise à jour de user avec le numéro formaté avant de l'envoyer
        const loginSuccess = await userLogin({ ...user, tel: formattedPhone });
        if (loginSuccess) {
            setErrorMessage('');
            const { from, idevent } = location.state || { from: '/', idevent: null };
            if (from === '/annonce' && idevent) {
                sessionStorage.setItem('currentIdevent', idevent);
                sessionStorage.setItem('hasPassedRedirect', 'true');
                navigate('/annonce', { replace: true });
            } else {
                navigate(from, { replace: true });
            }
        } else {
            setErrorMessage('Erreur ! Veuillez vérifier vos informations.');
        }
    };

    const gotoSignUpPage = () => navigate("/preference");

    return (
        <div className="login__container">
            <h2 style={{textAlign:'left'}}>CONNECTE TON COMPTE</h2>
            <h3 className="h3">C'est facile !</h3>
            <div>
                <img src={LoginP} alt="Login" className="login-image" />
            </div>
            <form className="login__form" onSubmit={handleSubmit}>
                {/* <TextField 
                    label="Prénom" 
                    value={user.username} 
                    onChange={(e) => setUser({ ...user, 'username': e.target.value })} 
                    variant="outlined" 
                    className="input-field"
                    fullWidth
                    required 
                /> */}
                <MuiTelInput 
                    label="Numéro WhatsApp" 
                    defaultCountry="BJ"
                    value={user.tel} 
                    onChange={handlePhoneChange}
                    variant="outlined" 
                    className="input-field"
                    fullWidth
                    required
                    error={!!phoneError}
                    helperText={phoneError}
                    forceCallingCode
                />
                <TextField 
                    label="Mot de passe" 
                    value={user.mdp} 
                    onChange={(e) => setUser({ ...user, 'mdp': e.target.value })} 
                    variant="outlined" 
                    type="password" 
                    className="input-field"
                    fullWidth
                    required 
                />
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                <button 
                    className="loginBtn" 
                    type="submit"
                    disabled={!!phoneError}
                >
                    Je me connecte !
                </button>
                <p>
                    Vous n'avez pas de compte ?{" "}
                    <span className="link" onClick={gotoSignUpPage}>S'inscrire</span>
                </p>
            </form>
        </div>
    );
};

export default SignIn;