import { useNavigate } from "react-router-dom";
import client from "../../axiosClient";
import removePlusSign from "../removePlusSign";


/* const Register = async (user) => {
    var res = {}
    try {
        const reqBody = {
            username: user.username,
            mdp: user.mdp,
            nom: user.fullname,
            email: user.email,
            tel: removePlusSign(user.tel),
            preferences: user.preferences
        };

        const resp = await client.post("/annonceur", reqBody, {
            headers: {
                "Content-Type": "application/json",
            }
        });

        if (resp.data.error) {
            alert(resp.data.msg);
            return { success: false };
        } else {
            console.log(resp.data.data);
            localStorage.setItem("userId", resp.data.data.id);
            localStorage.setItem('username', resp.data.data.username)
            return { success: true, data: resp.data.data };
        }
    } catch (error) {
        console.error(error);
        if (error.response && error.response.data.error) {
            alert(error.response.data.msg);
        }
        return { success: false };
    }


}; */

const Register = async (user) => {
    try {
        const reqBody = {
            mdp: user.mdp,
            tel: removePlusSign(user.tel),
            nom: user.fullname,
            date_naissance: user.date_naissance,
            profession_id: user.profession_id,
            autre_profession: user.autre_profession,
            adresse: user.adresse,
            email: user.email,
            preference: Array.isArray(user.preferences) ? user.preferences.join(",") : "", // Vérifier si c'est un tableau
        };

        const resp = await client.post("/annonceur", reqBody, {
            headers: {
                "Content-Type": "application/json",
            }
        });

        if (resp.data.error) {
            alert(resp.data.msg);
            return { success: false };
        } else {
            localStorage.setItem("userId", resp.data.data.id);
            localStorage.setItem('tel', resp.data.data.tel);
            localStorage.setItem('email', resp.data.data.email);
            return { success: true, data: resp.data.data };
        }
    } catch (error) {
        console.error(error);
        if (error.response && error.response.data.error) {
            alert(error.response.data.msg);
        }
        return { success: false };
    }
};


export default Register