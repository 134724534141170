import React, { useState } from 'react';
import { Button } from "@mui/material";
import { BiFontFamily } from 'react-icons/bi';


const PrefButton = ({children, onClickFunction, condition  }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
    onClickFunction()
  };

  const buttonStyle = {
    backgroundColor: 'transparent', // pas de couleur de fond
    color: condition ? '#756EF3' : 'black', // texte en rouge
    padding: '5px 10px',
    margin: '2px 1px',
    border: '0.5px solid #FF3D00', // bordure rouge
    borderRadius: '10px', // bord arrondi
    cursor: 'pointer',
    textTransform: 'none',
  };



  return (
    <Button /* className="text-gray-800 px-4 py-2 rounded" */ style={buttonStyle} onClick={handleClick}>{children}</Button>

  );
};

export default PrefButton;
