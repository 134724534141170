import React from "react";
import { Route, Navigate, useNavigate, createRoutesFromElements, RouterProvider, createBrowserRouter } from "react-router-dom";
import ErrorPage from "./views/error-pages.js";
import ScanPage from "./views/main/ScanPage.js";
import Place from "./views/main/Place.js";
import useNetworkStatus from './hooks/useNetworkStatus.js';
import Page404 from './views/main/Page404.js';
import Root from "./views/Root.js";
import HomePage from "./views/main/HomePage.js";
import SignIn from "./views/landing/SignIn.js";
import { AuthProvider } from "./context/AuthContext.js";
import Multistep from "./views/landing/MultiStep.js";
import { FlowProvider } from "./context/FlowContext.js";
import MonCompte from "./views/main/MonCompte.js";
import OfflinePage from "./utils/OfflinePage.js";
import OnlyLoggedUserRoute from "./utils/OnlyLoggedUserRoute.js";
import OnlyLogOutUserRoute from "./utils/OnlyLogOutUserRoute.js";
import SidebarMenu from "./components/SidebarMenu.js";
import { SavedAds, customUseLoader } from "./views/main/SavedAds.js";
import { del } from "./utils/functions/ApiUtils.js";
import CooldownPage from "./views/main/CoolDownPage.js";
import RedirectHandler from "./views/main/RedirectHandler.js";
import useScanLimit from "./hooks/useScanLimit.js";



const ProtectedRoute = ({ children }) => {
  const hasPassedRedirect = sessionStorage.getItem('hasPassedRedirect') === 'true';
  return hasPassedRedirect ? children : <Navigate to="/" replace />;
};

const CooldownCheck = ({ children }) => {
  const { cooldownEnd } = useScanLimit();
  const navigate = useNavigate();
  
  React.useEffect(() => {
    if (cooldownEnd && new Date() < cooldownEnd) {
      navigate('/decompte');
    }
  }, [cooldownEnd, navigate]);

  return children;
};

const App = () => {

  const isOnline = useNetworkStatus();

  if (!isOnline) {
    return <OfflinePage />;
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorPage />} path="/" element={<Root />}>
        {/* <Route element={<OfflinePage />}> */}
          <Route path="/sidebar" element={<SidebarMenu />} />
          <Route path="/setup" element={<Multistep />} />

          <Route element={<OnlyLogOutUserRoute />}>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/preference" element={<Multistep />} />
            <Route path="/signup" element={<Multistep />} />
            <Route path="/setup-profil" element={<Multistep />} />
          </Route>

          <Route element={<OnlyLoggedUserRoute />}>
            <Route path="/annonces-gardees" 
              element={<SavedAds />} 
              loader={customUseLoader}
              action={async ({ params, request }) => {
                let data = await request.formData();
                return del(`/favoris/${data.get('id')}`);
              }} 
            />
            <Route path="/mon-compte" element={<MonCompte />} />
          </Route>

          <Route path="/" element={<HomePage />} />

          <Route 
            path="/scanpage" 
            element={
              <CooldownCheck>
                <ScanPage />
              </CooldownCheck>
            }
          />

          <Route 
            path="/redirect/:idevent" 
            element={
              <CooldownCheck>
                <RedirectHandler />
              </CooldownCheck>
            } 
          />

          <Route 
            path="/annonce" 
            element={
              <ProtectedRoute>
                <Place />
              </ProtectedRoute>
            } 
          />

          <Route path="/giftpage" element={<Place />} />

          <Route path="/decompte" element={<CooldownPage />} />
          <Route path="*" element={<Page404 />} />

          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        {/* </Route> */}
      </Route>
    )
  );

  return (
    <FlowProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </FlowProvider>
  );
};

export default App;